<template>
<p>Insert your verification code below:</p>
<form @submit.prevent="onSubmit">
  <div class="field flex flex-column gap-2 align-items-center">
    <InputText v-model="code" id="code" type="code" />
    <ErrorMessage name="code" />
  </div>
  <Button
    type="submit"
    :label="ctaLabel"
    :disabled="apiStatusStore.requests.get('post_user_email-verify') && apiStatusStore.requestFulfilled('post_user_email-verify')"
  />
</form>
<p>In case you didn't recive any email, request a new one</p>
<Button
  v-if="!apiStatusStore.requests.get('get_user_send-email-verify')"
  @click="onRequestNewEmailConfirm()"
  label="Request verification email"
/>
<p v-else>Verification email requested.</p> 
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '../stores/UserStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { ErrorMessage, GenericObject, useForm } from 'vee-validate';
import * as yup from 'yup';
import { RequestStatus } from '@/models/request.model';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

const route = useRoute(); 
const userStore = useUserStore();
const apiStatusStore = useApiStatusStore();

const emailVerifCode = route.params.emailVerifCode as string | undefined;

onMounted(() => {
  console.log('emailVerifCode', emailVerifCode);
  if(emailVerifCode){
    userStore.confirmEmail(emailVerifCode);
  }
});

const ctaLabel = computed(() => {
  const apiStatus = apiStatusStore.getRequestStatus('post_user_email-verify');
  switch (apiStatus){
    case RequestStatus.Pending :
      return 'Confirming...'
    case RequestStatus.Rejected :
      return 'Confirm email'
    case RequestStatus.Fulfilled :
      return 'Email confirmed'
    default:
      return 'Confirm email';
  }     
});

const schema = yup.object().shape({
  code: yup.string().required().label('code'),
});

const { defineField, handleSubmit } = useForm({
  validationSchema: schema,
});

const [code] = defineField('code');

const onSubmit = handleSubmit((values: GenericObject) => {
  userStore.confirmEmail(values.code);
});

function onRequestNewEmailConfirm(){
  userStore.requestConfirmEmail();
}

</script>
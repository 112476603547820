<template>
<div class="message" :id="message?.id">
    <div class="message-author"><b>{{ membersStore.memberUsername(message?.userId) }}</b></div>
    <div class="message-content mt-1">{{ message?.message }}</div>
</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import { ChatMessage } from '@/models/chat.model';
import { useMembersStore } from '@/stores/MembersStore';

defineProps({
  message: ChatMessage
});

const membersStore = useMembersStore();
</script>

<style scoped>
</style>
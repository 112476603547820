// Guild -----------------------------------------------------------------------

export interface ShortGuildDto {
    _id: string;
    name: string;
    private: boolean;
}

export class ShortGuild {
    id: string;
    name: string;
    private: boolean;

    constructor(res: ShortGuildDto) {
        this.id = res._id;
        this.name = res.name;
        this.private = res.private;
    }
}

export interface GuildDto {
    _id: string;
    name: string;
    description?: string;
    avatar?: string;
    coverImage?: string;
    private: boolean;
    owners: string[];
}

export class Guild {
    id: string;
    name: string;
    description?: string;
    avatar?: string;
    coverImage?: string;
    private: boolean;
    owners: string[];

    constructor(res: GuildDto) {
        this.id = res._id;
        this.name = res.name;
        this.description = res.description;
        this.avatar = res.avatar;
        this.coverImage = res.coverImage;
        this.private = res.private;
        this.owners = res.owners.map((id: string) => id);
    }
}

// Payload -----------------------------------------------------------------------

export class GuildRegisterPayload {
    name: string;
    isPrivate: boolean;
    description?: string;
    constructor(
        data: GuildRegisterPayload
    ){
        this.name = data.name;
        this.isPrivate = data.isPrivate;
        this.description = data.description;
    }
}

export class GuildUpdatePayload {
    id: string;
    name?: string;
    description?: string;
    avatar?: string;
    coverImage?: string;
    private?: boolean;

    constructor(
        data: GuildUpdatePayload
    ){
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.avatar = data.avatar;
        this.coverImage = data.coverImage;
        this.private = data.private;
    }
}
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { useUserStore } from "@/stores/UserStore";

export const beforeEachGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  next();
};

export const notAuthenticatedGuard =  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const userStore = useUserStore();
  if(userStore.isAuthenticated || userStore.jwt){
    next({ path: '/base' });
  } else {
    next();
  }
}

export const authenticatedGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const userStore = useUserStore();
  if(userStore.isAuthenticated || userStore.jwt){
    next();
  } else {
    next({ path: '/login' });
  }
}
import { defineStore } from "pinia";
import axios, { AxiosError } from 'axios';
import apiConfig from '../config/api';
import { User, LoginCredentials, UserRegistrationPayload } from "../models/user.model";
import { useSocketStore } from "./SocketStore";
import { computed, ref } from "vue";
import router from "@/router";

export const useUserStore = defineStore(`user`, () => {
  const socketStore = useSocketStore();

  //state
  const user = ref(null as User | null);
  const loaded = ref(false);
  const error = ref(null as AxiosError | null);

  //getters
  const isAuthenticated = computed(() => {
    return user.value !== null;
  });
  const emailIsConfirmed = computed(() => {
    return user.value?.emailConfirmed;
  });
  const jwt = computed(() => {
    return localStorage.getItem("jwt");
  });

  //actions
  async function tryLogin() {
    await fetchUser()
  }

  async function loginUser(loginCredentials: LoginCredentials) {
    await axios.post(apiConfig.baseURL + '/auth/login', loginCredentials)
      .then(res => {
        localStorage.setItem("jwt", res.data.token.token);
        user.value = new User(res.data.user);
        loaded.value = true;
      })
  }

  async function logOutUser() {
    localStorage.removeItem("jwt");
    user.value = null;
    loaded.value = false;
    router.push({ name: 'home' });
    socketStore.disconnect();
  }

  async function registerUser(formData: UserRegistrationPayload) {
    await axios.post(apiConfig.baseURL + '/user/register', formData)
      .then(res => {
        localStorage.setItem("jwt", res.data.token.token);
        user.value = new User(res.data.user);
        loaded.value = true;
        router.push({ name: 'register-thank-you' });
      })
  }

  async function confirmEmail(emailVerifCode: string){
    const body = { emailVerifCode }
    axios.post(apiConfig.baseURL + '/user/email-verify', body)
      .then(() => {
        if(user.value){ user.value.emailConfirmed = true; }
        router.push({ name: 'confirm-email-thank-you' });
      })
  }

  async function requestConfirmEmail(){
    axios.get(apiConfig.baseURL + '/user/send-email-verify')
      .then(res => {
        console.log(res)
      })
  }

  function setUser(usr: User) {
    console.log('Setting user', usr)
    user.value = usr;
  }

  async function fetchUser() {
    console.log('fetchUser')
    const jwt = localStorage.getItem("jwt");
    if (jwt){
      await axios.get(apiConfig.baseURL + '/auth/user')
        .then(res => {
          user.value = new User(res.data.user);
          loaded.value = true;
        })
        .catch(() => {
          logOutUser();
        });
    }
  }

  return {
    user,
    loaded,
    error,
    isAuthenticated,
    emailIsConfirmed,
    jwt,
    tryLogin,
    loginUser,
    logOutUser,
    registerUser,
    confirmEmail,
    requestConfirmEmail,
    setUser,
    fetchUser
  };
});
<template>
<div class="delete" v-if="!apiStatusStore.requests.get('delete_channel-group')">
  <p>This action is irreversible.</p>
  <div class="cta-section flex flex-row gap-3 justify-content-end">
    <Button
      label="Cancel"
      @click="$emit('close')"
    />
    <Button
      label="Delete"
      @click="onDelete"
      :disabled="apiStatusStore.requestPending('delete_channel-group')"
    />
  </div>
</div>
<div class="delete-pending" v-if="apiStatusStore.requestPending('delete_channel-group')">
  <p>Deleting...</p>
</div>
<div class="delete-complete" v-if="apiStatusStore.requestFulfilled('delete_channel-group')">
  <div class="cta-section flex flex-row gap-3 justify-content-end">
    <Button
      label="Close"
      @click="closeOnDeleted"
    />
  </div>
</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, watch } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useDialogStore } from '@/stores/DialogStore';
import Button from 'primevue/button';

const guildStore = useGuildStore();
const apiStatusStore = useApiStatusStore();
const dialogStore = useDialogStore();
const emit = defineEmits(['close']);

const props = defineProps<{
  props: {
    channelGroupId: string
  }
}>()

function onDelete(){
  guildStore.deleteChannelGroup(props.props.channelGroupId);
}

function closeOnDeleted(){
  emit('close');
}

watch(() => apiStatusStore.requestFulfilled('delete_channel-group'), (newValue) => {
  if (newValue) {
    dialogStore.changeHeader('Channel group succesfully deleted')
  }
});
</script>
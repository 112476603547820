import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/auth/LoginView.vue";
import RegisterView from "../views/auth/RegisterView.vue";
import RegisterThankYouView from "../views/auth/RegisterThankYouView.vue";
import EmailConfirmView from "../views/auth/EmailConfirmView.vue";
import EmailConfirmThankYouView from "../views/auth/EmailConfirmThankYouView.vue";
import BaseView from "../views/BaseView.vue";
import TheGuild from "../components/TheGuild.vue";
import TheGuildExplore from "@/components/TheGuildExplore.vue";
import NotFoundView from "../views/NotFoundView.vue";
import { authenticatedGuard, beforeEachGuard, notAuthenticatedGuard } from "./guards";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: notAuthenticatedGuard
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    beforeEnter: notAuthenticatedGuard
  },
  {
    path: "/register/thank-you",
    name: "register-thank-you",
    component: RegisterThankYouView,
    beforeEnter: authenticatedGuard
  },
  {
    path: "/confirm-email/:emailVerifCode?",
    name: "confirm-email",
    component: EmailConfirmView,
    props: true,
    beforeEnter: authenticatedGuard
  },
  {
    path: "/confirm-email/thank-you",
    name: "confirm-email-thank-you",
    component: EmailConfirmThankYouView,
    beforeEnter: authenticatedGuard
  },
  {
    path: "/base",
    name: "base",
    component: BaseView,
    beforeEnter: authenticatedGuard,
    children: [
      {
        path: "guild/:guildId/:channelId?",
        name: "guild",
        component: TheGuild,
        props: true
      },
      {
        path: "guild-explore",
        name: "guild-explore",
        component: TheGuildExplore,
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFoundView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(beforeEachGuard);

export default router;
<template>
<section id="email-confirmed-thank-you" class="flex flex-column text-center">
  <h1 class="mt-5">Your email was confirmed!</h1>
  <p>Thank you for confirming your email. You can now start using SquadronBase.</p>
  <Button
    label="Go to SquadronBase"
    class="mx-auto"
    @click="onGoToBase"
  />
</section>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import Button from 'primevue/button';

const router = useRouter();

const onGoToBase = () => {
  router.push({ name: 'base' });
};
</script>
<template>
<section id="Register" class="flex flex-column text-center">
  <h1>Register</h1>
  <user-register />
  <p>If you already have an account:</p>
  <Button
    label="Go to Login"
    class="mx-auto"
    @click="onGoToLogin"
  />
</section>
</template>

<script setup lang="ts">
import UserRegister from '@/components/UserRegister.vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';

const router = useRouter();

function onGoToLogin(){
  router.push('/login');
}
</script>
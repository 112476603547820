export class User {
  _id?: string;
  username?: string;
  handle?: string;
  email?: string;
  emailConfirmed?: boolean;
  avatarUrl?: string;
  birthdate?: Date;
  //friendList: Types.ObjectId[];
  //servers: Types.ObjectId[];
  //ownedServers: Types.ObjectId[];
  //invites: Types.ObjectId[];
  constructor(data: object) {
      const allowedProperties = [ '_id', 'username', 'handle', 'email', 'emailConfirmed', 'avatarUrl', 'birthdate' ];
      const filteredData = Object.fromEntries(Object.entries(data).filter(([key]) => allowedProperties.includes(key)));
      Object.assign(this, filteredData);
  }
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export class UserRegistrationPayload {
  email: string;
  username: string;
  handle: string;
  password: string;
  confirmPassword: string;
  birthDate: Date;

  constructor(data: UserRegistrationPayload) {
    this.email = data.email;
    this.username = data.username;
    this.handle = data.handle;
    this.password = data.password;
    this.confirmPassword = data.confirmPassword;
    this.birthDate = data.birthDate;
  }
}
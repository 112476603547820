<template>
  <guild-navigation />
  <guild-chat v-if="channelId"/>
  <members-list />
</template>

<script setup lang="ts">
import { defineProps, watch } from 'vue';
import GuildNavigation from './guild/GuildNavigation.vue';
import GuildChat from './guild/GuildChat.vue';
import MembersList from './guild/GuildMembersList.vue';
import { useGuildStore } from '@/stores/GuildStore';

const guildStore = useGuildStore();

const props = defineProps<{ 
  guildId: string,
  channelId?: string
}>();

watch(() => props.guildId, (newGuildId, oldGuildId) => {
  if (newGuildId !== oldGuildId && newGuildId != null) {
    guildStore.getGuildById(newGuildId);
  }
}, { immediate: true });

watch(() => props.channelId, (newChannelId, oldChannelId) => {
  if (newChannelId !== oldChannelId && newChannelId != null) {
    guildStore.setActiveChannel(newChannelId);
  }
}, { immediate: true });
</script>

<style scoped>
</style>
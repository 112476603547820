<template>
<section id="server-navigation" class="full-height-section gap-2">
  <router-link
    class="guild-link"
    v-for="guild in guildNavigationStore.guilds"
    :key="guild.id"
    :to="{ name: 'guild', params: { guildId: guild.id } }"
  >
    {{ guild.name }}
  </router-link>
  <router-link to="/base/guild-explore" style="margin-top: 1rem;">Explore guilds</router-link>
  <Button
    label="Create guild"
    class=""
    @click="onCreateGuild()"
  />

  <Button
    label="Log Out"
    class="mt-auto"
    @click="userStore.logOutUser"
  />

</section>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import GuildCreate from './guild/GuildCreate.vue';
import { useUserStore } from '../stores/UserStore';
import { useGuildNavigationStore } from '@/stores/GuildNavigationStore';
import { useDialogStore } from '@/stores/DialogStore';
import Button from 'primevue/button';

const userStore = useUserStore();
const guildNavigationStore = useGuildNavigationStore();
const dialogStore = useDialogStore();

onMounted(() => {
  guildNavigationStore.fetchGuilds();
});

function onCreateGuild(){
  dialogStore.openDialog(GuildCreate, 'Create your new guild');
}

</script>

<style scoped>
#server-navigation{
  display: flex;
  flex-direction: column;
  width: 250px;
  border-right: 1px solid black;
  overflow: scroll;

  .guild-link{
    margin-top: 0.25rem;
    font-weight: bold;
  }

  .router-link-active, a:hover{
    background-color: lightgray;
  }
}
</style>
<template>
<form @submit.prevent="onSubmit" class="mx-auto">
  <div class="field flex flex-column gap-2 align-items-start">
    <label for="email">Email</label>
    <InputText v-model="email" id="email" type="email" />
    <small id="fullName-help" class="p-error">
      {{ errors.email }}
    </small>
  </div>
  <div class="field flex flex-column gap-2 align-items-start">
    <label for="username">Username</label>
    <InputText v-model="username" id="username" type="text" />
    <small id="fullName-help" class="p-error">
      {{ errors.username }}
    </small>
  </div>
  <div class="field flex flex-column gap-2 align-items-start">
    <label for="handle">Handle</label>
    <InputText v-model="handle" id="handle" type="text" />
    <small id="fullName-help" class="p-error">
      {{ errors.handle }}
    </small>
  </div>
  <div class="field flex flex-column gap-2 align-items-start">
    <label for="password">Password</label>
    <InputText v-model="password" id="password" type="password" />
    <small id="fullName-help" class="p-error">
      {{ errors.password }}
    </small>
  </div>
  <div class="field flex flex-column gap-2 align-items-start">
    <label for="confirmPassword">Confirm Password</label>
    <InputText v-model="confirmPassword" id="confirmPassword" type="password" />
    <small id="fullName-help" class="p-error">
      {{ errors.confirmPassword }}
    </small>
  </div>
  <div class="field flex flex-column gap-2 align-items-start">
    <label for="birthDate">Birth Date</label>
    <InputText v-model="birthDate" id="birthDate" type="date" />
    <small id="fullName-help" class="p-error">
      {{ errors.birthDate }}
    </small>
  </div>
  <Button
    type="submit"
    :label="ctaLabel"
    :disabled="apiStatusStore?.requestPending('register')"
  />
</form>
</template>

<script setup lang="ts">
import { RequestStatus } from '@/models/request.model';
import { useUserStore } from '../stores/UserStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useForm, configure } from 'vee-validate';
import { computed } from 'vue';
import * as yup from 'yup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

const userStore = useUserStore();
const apiStatusStore = useApiStatusStore();

const ctaLabel = computed(() => {
  const apiStatus = apiStatusStore.getRequestStatus('register');
  switch (apiStatus){
    case RequestStatus.Pending :
      return 'Registering...'
    case RequestStatus.Rejected :
      return 'Register'
    default:
      return 'Register';
  }     
});

const schema = yup.object().shape({
  email: yup.string().required().email().label('Email Address'),
  username: yup.string().required().min(3).label('Username'),
  handle: yup.string().required().min(3).label('Handle'),
  password: yup.string().required().min(8)
    .matches(/(?=.*[0-9])/, 'Password must contain at least one number')
    .matches(/(?=.*[@#$%^&+=.,!?])/, 'Password must contain at least one special character').label('Password'),
  confirmPassword: yup.string().required()
    .oneOf([yup.ref('password')], 'Passwords must match').label('Confirm Password'),
  birthDate: yup.date().required().label('Birth Date')
});

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: schema,
});

const [email] = defineField('email');
const [username] = defineField('username');
const [handle] = defineField('handle');
const [password] = defineField('password');
const [confirmPassword] = defineField('confirmPassword');
const [birthDate] = defineField('birthDate');

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

const onSubmit = handleSubmit((values: any) => {
  userStore.registerUser(values);
});

</script>
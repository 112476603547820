import { defineStore } from 'pinia';
import { io, Socket } from 'socket.io-client';
import apiConfig from '../config/api';
import { useChatStore } from './ChatStore';

import { ChannelEvent, GuildEvent, NavigationEvent } from '@/models/socket.model';
import { InitMembersState, OnlineMemberEvent } from '@/models/members.model';
import { ChatMessageDto, CreateChatMessagePayload } from '@/models/chat.model';
import { useGuildStore } from './GuildStore';
import { useMembersStore } from './MembersStore';
import { ref, watch } from 'vue';
import { useUserStore } from './UserStore';

const apiUrl = apiConfig.baseURL;

export const useSocketStore = defineStore(`socket`, () => {
  const userStore = useUserStore();

  //state
  const socket = ref(null as Socket | null);
  const isConnected = ref(false);

  //getters

  //onStoreInit
  function onStoreInit() {
    watch(() => userStore.isAuthenticated && userStore.emailIsConfirmed, (newValue, oldValue) => {
      if (newValue && !oldValue) {
        console.log('connect onStoreInit', newValue);
        connect();
      } else if (!newValue && oldValue) {
        console.log('disconnect onStoreInit', newValue);
        disconnect();
      }
    }, { immediate: true });
  }

  //actions
  function connect() {
    socket.value = io(apiUrl, {
      query: { authToken: localStorage.getItem('jwt') },
      autoConnect: false,
      transports: ['websocket']
    });

    socket.value.on('connect', () => {
      isConnected.value = true;
      console.log('Connected to Socket.IO');
      initListeners();
    });

    socket.value.on('disconnect', () => {
      isConnected.value = false;
      console.log('Disconnected from Socket.IO');
    });

    socket.value.connect();
  }

  function disconnect() {
    if (socket.value) {
      socket.value.disconnect();
      isConnected.value = false;
    }
  }

  //Socket listeners -----------------------------------------------------------------
  function initListeners(): void {
    if (socket.value) {
      socket.value.on('initMembersState', (initMembersState: InitMembersState) => {
        console.log('initMembersState$: ', initMembersState);
        const membersStore = useMembersStore();
        membersStore.setInitMembersState(initMembersState);
      });

      socket.value.on('memberStatusUpdate', (onlineMemberEvent: OnlineMemberEvent) => {
        console.log('memberStatusUpdate$: ', onlineMemberEvent);
        const membersStore = useMembersStore();
        membersStore.memberStatusUpdate(onlineMemberEvent);
      });

      socket.value.on('guild', (guildEvent: GuildEvent) => {
        console.log('guildEventRecived$: ', guildEvent);
      });

      socket.value.on('channel', (channelEvent: ChannelEvent) => {
        console.log('channelEventRecived$: ', channelEvent);
        const guildStore = useGuildStore();
        guildStore.handleChannelEvent(channelEvent);
      });

      socket.value.on('chatMessage', (data: ChatMessageDto) => {
        console.log('newChatMessage$: ', data)
        const chatStore = useChatStore();
        chatStore.onNewMessageRecived(data);
      });

    }
  }

  //Socket emitters -----------------------------------------------------------------
  function sendNavigationEvent(navigationEvent: NavigationEvent) {
    if (socket.value) {
      console.log('sendNavigationEvent$: ', navigationEvent);
      socket.value.emit('navigation', navigationEvent);
    }
  }

  function sendChatMessage(chatMessage: CreateChatMessagePayload) {
    if (socket.value) {
      console.log('sendChatMessage$: ', chatMessage);
      socket.value.emit('createMessage', chatMessage);
    }
  }

  onStoreInit();

  return {
    socket,
    isConnected,
    connect,
    disconnect,
    sendNavigationEvent,
    sendChatMessage,
    onStoreInit,
  };
});
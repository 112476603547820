const API_CONFIG = {
  development: {
    baseURL: 'http://localhost:3000',
  },
  staging: {
    baseURL: 'https://api.staging.squadronbase.com',
  },
  production: {
    baseURL: 'https://your-production-api-url',
  },
};
  
export default API_CONFIG[process.env.NODE_ENV as keyof typeof API_CONFIG];
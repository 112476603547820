import { defineStore } from 'pinia';
import axios, { AxiosError } from 'axios';
import apiConfig from '../config/api';
import { useSocketStore } from './SocketStore';

import { ChatMessage, ChatMessageDto, CreateChatMessagePayload } from '@/models/chat.model';
import { useGuildStore } from './GuildStore';
import { computed, ref } from 'vue';

//converting the store to setup
export const useChatStore = defineStore(`chat`, () => {
  const guildStore = useGuildStore();

  //state
  const messages = ref([] as ChatMessage[]);
  const loaded = ref(false);
  const error = ref(null as AxiosError | null);

  //getters
  const firstMessage = computed(() => messages.value[0]);
  const lastMessage = computed(() => messages.value[messages.value.length - 1]);

  //actions
  async function onChannelJoined(channelId: string){
    messages.value = [];
    loaded.value = false;
    await fetchMessages(channelId);
  }

  async function fetchMessages(channelId: string){
    const params = { channelId };
    await axios.get(apiConfig.baseURL + "/message", { params })
      .then((response) => {
        messages.value = response.data.reverse().map((message: ChatMessageDto) => new ChatMessage(message));
        loaded.value = true;
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  async function fetchOlderMessages(){
    const lastMessageId = firstMessage.value.id;
    const channelId = guildStore.activeChannel?.id;
    const params = { channelId, lastMessageId };
    await axios.get(apiConfig.baseURL + "/message", { params })
      .then((response) => {
        const newMessages = response.data.reverse().map((message: ChatMessageDto) => new ChatMessage(message));
        messages.value = [ ...newMessages, ...messages.value];
      })
  }

  async function sendMessage(message: string){
    if(guildStore.guild && guildStore.activeChannelGroup && guildStore.activeChannel){
      const payload = new CreateChatMessagePayload(
        message,
        guildStore.guild.id,
        guildStore.activeChannelGroup.id,
        guildStore.activeChannel.id
      );
      const socketStore = useSocketStore();
      socketStore.sendChatMessage(payload);  
    } else {
      console.error("Cannot send message: Guild, ChannelGroup or Channel is not set");
    }
  }

  async function onNewMessageRecived(chatMessage: ChatMessageDto){
    messages.value = [ ...messages.value, new ChatMessage(chatMessage)];
  }

  return {
    messages,
    loaded,
    error,
    firstMessage,
    lastMessage,
    onChannelJoined,
    fetchMessages,
    fetchOlderMessages,
    sendMessage,
    onNewMessageRecived
  }
});
<template>
<div class="create-form" v-if="!apiStatusStore.requests.get('post_channel-group')">
  <form  @submit.prevent="onSubmit">
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="name">Name</label>
      <InputText v-model="name" id="name" />
      <small id="fullName-help" class="p-error">
        {{ errors.name }}
      </small>
    </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="position">Position</label>
      <InputText v-model="position" id="position" type="number" />
      <small id="fullName-help" class="p-error">
        {{ errors.position }}
      </small>
    </div>
    <div class="cta-section flex flex-row gap-2 justify-content-end">
      <Button
        label="Cancel"
        @click="dialogStore.closeDialog()"
      />
      <Button
        type="submit"
        label="Create"
        :disabled="apiStatusStore.requestPending('post_channel-group')"
      />
    </div>
  </form>
</div>
<div class="create-pending" v-if="apiStatusStore.requestPending('post_channel-group')">
  <p>Creating channel group...</p>
</div>
<div class="create-complete" v-if="apiStatusStore.requestFulfilled('post_channel-group')">
  <p>You can now populate the channel group with channels</p>
  <div class="cta-section flex flex-row gap-3 justify-content-end">
    <Button
      label="Close"
      @click="dialogStore.closeDialog()"
    />
  </div>
</div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useDialogStore } from '@/stores/DialogStore';
import { configure, useForm } from 'vee-validate';
import * as yup from 'yup';
import { CreateChannelGroupPayload } from '@/models/channel-list.model';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

const guildStore = useGuildStore();
const apiStatusStore = useApiStatusStore();
const dialogStore = useDialogStore();

const schema = yup.object({
  name: yup.string().min(4).required(),
  position: yup.number().default(0),
});

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: schema,
});

const [name] = defineField('name');
const [position] = defineField('position');

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

const onSubmit = handleSubmit(async (values: any) => {
  const payload = new CreateChannelGroupPayload(values);
  await guildStore.createChannelGroup(payload);
});

watch(() => apiStatusStore.requestFulfilled('post_channel-group'), (newValue) => {
  if (newValue) {
    useDialogStore().changeHeader('Channel group succesfully created')
  }
});
</script>
<template>
<div class="create-form" v-if="!apiStatusStore.requests.get('post_channel')">
  <form @submit.prevent="onSubmit">
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="name">Name</label>
      <InputText v-model="name" id="name" />
      <ErrorMessage name="name" />
    </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="description">Description</label>
      <InputText v-model="description" id="description" />
      <ErrorMessage name="description" />
    </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="type">Type</label>
      <Dropdown v-model="type" id="type" :options="channelTypes" optionLabel="name" optionValue="id" />
      <ErrorMessage name="type" />
    </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="position">Position</label>
      <InputText v-model="position" id="position" type="number" />
      <ErrorMessage name="position" />
    </div>
    <div class="cta-section">
      <Button
        label="Cancel"
        @click="dialogStore.closeDialog()"
      />
      <Button
        type="submit"
        label="Create Channel"
        :disabled="apiStatusStore.requestPending('post_channel')"
      />
    </div>
  </form>
</div>
<div class="create-pending" v-if="apiStatusStore.requestPending('post_channel')">
  <p>Creating channel...</p>
</div>
<div class="create-complete" v-if="apiStatusStore.requestFulfilled('post_channel')">
  <div class="cta-section flex flex-row gap-3 justify-content-end">
    <Button
      label="Close"
      @click="dialogStore.closeDialog()"
    />
  </div>
</div>
</template>

<script setup lang="ts">
import { defineProps, watch } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useDialogStore } from '@/stores/DialogStore';
import { ErrorMessage, useForm, configure, GenericObject } from 'vee-validate';
import * as yup from 'yup';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import { ChannelGroup, CreateChannelPayload } from '@/models/channel-list.model';

const guildStore = useGuildStore();
const apiStatusStore = useApiStatusStore();
const dialogStore = useDialogStore();

const props = defineProps<{
  props: {
    group: ChannelGroup
  }
}>();

const schema = yup.object({
  name: yup.string().min(4).required(),
  description: yup.string(),
  type: yup.number().required(), //option 1 = Chat, 2 = Announcements, 3 = Voice, 4 = Advanced voice
  position: yup.number(),
});

const channelTypes = [
  { id: 1, name: 'Chat' },
  { id: 2, name: 'Announcements' },
  { id: 3, name: 'Voice' },
  { id: 4, name: 'Advanced Voice' },
];

const { defineField, handleSubmit } = useForm({
  validationSchema: schema,
});

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

const [name] = defineField('name');
const [description] = defineField('description');
const [type] = defineField('type');
const [position] = defineField('position');

const onSubmit = handleSubmit((values: GenericObject) => {
  if(guildStore.guild){
    const body: CreateChannelPayload = {
      guildId: guildStore.guild.id,
      channelGroupId: props.props.group.id,
      name: values.name,
      description: values.description,
      type: values.type,
      position: values.position,
    }
    guildStore.createChannel(body);
  } else {
    console.error('Cannot create channel, guild is undefined');
  }
});

watch(() => apiStatusStore.requestFulfilled('post_channel'), (newValue) => {
  if (newValue) {
    useDialogStore().changeHeader('Channel succesfully created!')
  }
});
</script>
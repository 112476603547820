<template>
<section id="search-guild" class="p-3">
  <h2 class="mt-0">Find your community on squadron!</h2>
  <form @submit.prevent="onSubmit" :validation-schema="schema">
    <div class="flex flex-row chat-input gap-3">
      <InputText v-model="guildName" id="guildName" type="destextription" :class="{ 'p-invalid': errors.guildName }"/>
      <Button
        type="submit"
        label="Search"
      />
    </div>
    <small id="fullName-help" class="p-error">
      {{ errors.guildName }}
    </small>
  </form>
  <div v-if="searchResults.guilds.length > 0">
    <h3>Search Results</h3>
    <ul>
      <li v-for="guild in searchResults.guilds" :key="guild.id">
        <router-link :to="{ name: 'guild', params: { guildId: guild.id }}">{{ guild.name }}</router-link>
      </li>
    </ul>
  </div>
</section>
</template>

<script setup lang="ts">
import { GenericObject, configure, useForm } from 'vee-validate';
import { reactive } from 'vue';
import * as yup from 'yup';
import { GuildDto, ShortGuild } from '@/models/guild.model';
import axios from 'axios';
import apiConfig from "@/config/api";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

const schema = yup.object({
  guildName: yup.string().min(3).required()
});

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: schema,
});

const [guildName] = defineField('guildName');

const searchResults = reactive({
  guilds: [] as ShortGuild[]
});

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

const onSubmit = handleSubmit (async (values: GenericObject) => {
  try {
    const params = {
      searchTerm: values.guildName
    };
    console.log(params);

    const response = await axios.get(`${apiConfig.baseURL}/guild/search`, { params });
    console.log(response.data);
    searchResults.guilds = response.data.map((guild: GuildDto) => new ShortGuild(guild));
  } catch (error) {
    console.error(error);
  }
})
</script>

<style scoped>
</style>
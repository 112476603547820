import AVLTree from "avl";

export enum OnlineMemberStatus {
    online,
    offline
}

export interface MemberDto {
    _id: string;
    userId: string;
    username: string;
    handle: string;
    avatar: string;
}

export class Member {
    id: string;
    userId: string;
    username: string;
    handle: string;
    avatar: string;
    constructor(member: MemberDto | Member){
        this.id = ('_id' in member) ? member._id.toString() : member.id.toString();
        this.userId = ('userId' in member) ? member.userId.toString() : this.id;
        this.username = member.username;
        this.handle = member.handle;
        this.avatar = member.avatar;
    }
}

/**
 * @param user - User document from database
 * @param status - OnlineMemberStatus
 */
export class OnlineMember extends Member {
    status: OnlineMemberStatus;
    constructor(member: Member, status: OnlineMemberStatus){
        super(member);
        this.status = status;
    }
}

/**
 * @param guildId - Guild ID
 * @param onlineMember - OnlineMember
 */
export interface OnlineMemberEvent {
    guildId: string;
    onlineMember: OnlineMember;
}

export interface InitMembersState {
    onlineMembers: OnlineMember[];
    offlineMembers: OnlineMember[];
}

//-------------------------------------------------------------------------------------------------------------------

type UserId = string;
type Username = string;

export class MembersState {
    constructor(
        public allMembers: Map<UserId, Member> = new Map(),
        public onlineMembersSortedByUsername: AVLTree<Username, OnlineMember> = new AVLTree((a, b) => a.localeCompare(b)),
        public offlineMembersSortedByUsername: AVLTree<Username, OnlineMember> = new AVLTree((a, b) => a.localeCompare(b)),
        public loaded: boolean = false,
        public error: string | null = null
    ){
        this.offlineMembersSortedByUsername = new AVLTree<Username, OnlineMember>((a, b) => a.localeCompare(b));
        this.onlineMembersSortedByUsername = new AVLTree<Username, OnlineMember>((a, b) => a.localeCompare(b));
    }
}
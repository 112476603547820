import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import apiConfig from '../config/api';
import { useApiStatusStore } from '../stores/ApiStatusStore';
import { RequestStatus } from '@/models/request.model';

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const method = config.method ? config.method : '';
  let url = config.url ? config.url.replace(apiConfig.baseURL, '') : '';
  url = url.replace(/\//g, '_');
  const urlWithoutParams = url.split('?')[0];
  const requestName = `${method}${urlWithoutParams}`;

  useApiStatusStore().setRequestStatus(requestName, { status: RequestStatus.Pending, error: null });

  return config;
});

axios.interceptors.response.use(
  (response) => {
    const method = response.config.method ? response.config.method : '';
    let url = response.config.url ? response.config.url.replace(apiConfig.baseURL, '') : '';
    url = url.replace(/\//g, '_');
    const urlWithoutParams = url.split('?')[0];
    const requestName = `${method}${urlWithoutParams}`;
  
    useApiStatusStore().setRequestStatus(requestName, { status: RequestStatus.Fulfilled, error: null });

    return response;
  },
  (error: AxiosError) => {
    const method = error.config?.method ? error.config.method : '';
    let url = error.config?.url ? error.config.url.replace(apiConfig.baseURL, '') : '';
    url = url.replace(/\//g, '_');
    const urlWithoutParams = url.split('?')[0];
    const requestName = `${method}${urlWithoutParams}`;

    useApiStatusStore().setRequestStatus(requestName, { status: RequestStatus.Rejected, error: error.message });

    return Promise.reject(error);
  }
);

export default axios;
<template>
  <router-view v-if="loadingStore.initLoadingDone"/>
  <p v-else>Loading</p>
  <Dialog v-if="dialogStore.isVisible"
    :visible="dialogStore.isVisible"
    :header="dialogStore.header!"
    dismissable-mask
    modal
    closable
    @update:visible="onCloseDialog()"
  >
    <component
      v-if="dialogStore.component"
      :is="dialogStore.component"
      :props="dialogStore.props"
      @close="dialogStore.closeDialog"
    />
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog';
import { useLoadingStore } from './stores/LoadingStore';
import { useDialogStore } from './stores/DialogStore';

const loadingStore = useLoadingStore();
const dialogStore = useDialogStore();

function onCloseDialog(){
  console.log('closing dialog');
  dialogStore.closeDialog();
}

</script>

<style lang="scss">
</style>
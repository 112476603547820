<template>
<section id="guild-navigation" class="full-height-section" v-if="guildStore.guild && guildStore.loaded">

  <div
    class="guild-header"
  >
    <div class="guild-ctas flex flex-row gap-3 mt-3">
      <h2>{{ guildStore.guild.name }}</h2>
      <Button
        v-if="guildStore.isOwner"
        icon="pi pi-ellipsis-v"
        size="small"
        class="ml-auto"
        text plain
        @click="onGuildHeaderClick"
      />
      <ContextMenu ref="guildHeaderMenu" :model="guildHeaderMenuItems" />
    </div>
    <div class="guild-ctas flex flex-row gap-3 mt-3">
      <Button
        v-if="!guildStore.isMember"
        label="Join Guild"
        size="small"
        @click="guildStore.joinGuild()"
      />
      <Button
        v-else
        label="Leave Guild"
        size="small"
        @click="guildStore.leaveGuild()"
      />
    </div>
  </div>

  <div class="groups">
    <div class="group"  >
      <Accordion :multiple="true" :activeIndex="groupsIndexes" expandIcon="pi pi-chevron-up" collapseIcon="pi pi-chevron-up">
        <AccordionTab
          v-for="group in guildStore.channelsList?.groups"
          :key="group.id"
        >
          <template #header>
            <div class="group-header flex flex-row flex-grow-1">
              <small>{{ group.name }}</small>
              <Button
                v-if="guildStore.isOwner"
                icon="pi pi-ellipsis-v"
                size="small"
                text plain
                class="p-0 ml-auto"
                @click="onGroupHeaderClick($event, group)"
              />
              <Button
                v-if="guildStore.isOwner"
                icon="pi pi-plus"
                size="small"
                text plain
                class="p-0"
                @click="onCreateChannel(group)"
              />
            </div>
          </template>
          <div class="channel flex flex-row align-items-center gap-2"
            v-for="channel in group.channels"
            :key="channel.id"
          >
            <router-link class="channel" :to="{ name: 'guild', params: { channelId: channel.id }}">
              <i :class="channel.getChannelIcon()" style="font-size: 0.95rem"></i> - {{ channel.name }}
            </router-link>
            <div class="channel-ctas ml-auto flex flex-row gap-1 justify-content-end">
              <Button
                v-if="guildStore.isOwner"
                icon="pi pi-ellipsis-v"
                size="small"
                text plain
                class="p-0 ml-auto"
                @click="onChannelMenuClick($event, group, channel)"
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <ContextMenu ref="groupMenu" :model="groupMenuItems" />
      <ContextMenu ref="channelMenu" :model="channelMenuItems" />
    </div>
  </div>
  <Button @click="onCreateChannelGroup()">Create channel group</Button>
</section>
<div v-else>
  <p>Loading...</p>
</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useDialogStore } from '@/stores/DialogStore';
import { Channel, ChannelGroup } from '@/models/channel-list.model';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import GuildDelete from './GuildDelete.vue';
import GuildChannelGroupCreate from './Channels//GuildChannelGroupCreate.vue';
import GuildChannelGroupEdit from './Channels/GuildChannelGroupEdit.vue';
import GuildChannelGroupDelete from './Channels/GuildChannelGroupDelete.vue';
import GuildChannelCreate from './Channels/GuildChannelCreate.vue';
import GuildChannelEdit from './Channels/GuildChannelEdit.vue';
import GuildChannelDelete from './Channels/GuildChannelDelete.vue';
import GuildEdit from './GuildEdit.vue';
import ContextMenu from 'primevue/contextmenu';

const guildStore = useGuildStore();
const dialogStore = useDialogStore();
const groupsIndexes = ref<number[]>([]);

// Guild header context menu ----------------------------------------
const guildHeaderMenu = ref();
const guildHeaderMenuItems = ref([
  {
    label: 'Edit Guild',
    icon: 'pi pi-pencil',
    command: () => onEditGuild()
  },
  {
    label: 'Delete Guild',
    icon: 'pi pi-trash',
    command: () => onDeleteGuild()
  },
]);

const onGuildHeaderClick = (event: Event) => {
  guildHeaderMenu.value.show(event);
};

// Group and channel context menu ----------------------------------------
const groupMenu = ref();
const groupMenuItems = ref([
  {
    label: 'Edit Group',
    icon: 'pi pi-pencil',
    command: () => onEditGroup()
  },
  {
    label: 'Delete Group',
    icon: 'pi pi-trash',
    command: () => onDeleteGroup()
  },
]);
const selectedGroup = ref<ChannelGroup>();

const onGroupHeaderClick = (event: Event, group: ChannelGroup) => {
  selectedGroup.value = group;
  groupMenu.value.show(event);
}

// Channel context menu ----------------------------------------
const channelMenu = ref();
const channelMenuItems = ref([
  {
    label: 'Edit channel',
    icon: 'pi pi-pencil',
    command: () => onEditChannel()
  },
  {
    label: 'Delete channel',
    icon: 'pi pi-trash',
    command: () => onDeleteChannel()
  },
]);
const selectedChannel = ref<Channel>();
const onChannelMenuClick = (event: Event, group: ChannelGroup, channel: Channel) => {
  selectedGroup.value = group;
  selectedChannel.value = channel;
  channelMenu.value.show(event);
}

function onEditGuild(){
  dialogStore.openDialog(GuildEdit, 'Edit this guild');
}

function onDeleteGuild(){
  dialogStore.openDialog(GuildDelete, 'Are you sure you want to delete this guild?');
}

function onCreateChannelGroup(){
  dialogStore.openDialog(GuildChannelGroupCreate, 'Create a new channel group');
}

function onEditGroup(){
  if(selectedGroup.value){
    dialogStore.openDialog(GuildChannelGroupEdit, 'Edit this group', { group: selectedGroup.value });
  } else {
    console.error('No group selected');
  }
}

function onDeleteGroup(){
  if(selectedGroup.value){
    dialogStore.openDialog(GuildChannelGroupDelete, 'Are you sure you want to delete this group?', { channelGroupId: selectedGroup.value.id });
  } else {
    console.error('No group selected');
  }
}

function onCreateChannel(group: ChannelGroup){
  if(group){
    dialogStore.openDialog(GuildChannelCreate, 'Create a new channel', { group });
  } else {
    console.error('No group selected');
  }
}

function onEditChannel(){
  if(selectedGroup.value && selectedChannel.value){
    dialogStore.openDialog(GuildChannelEdit, 'Edit this channel', { groupId: selectedGroup.value.id, channel: selectedChannel.value });
  } else {
    console.error('No group or channel selected');
  }
}

function onDeleteChannel(){
  if(selectedGroup.value && selectedChannel.value){
    dialogStore.openDialog(GuildChannelDelete, 'Are you sure you want to delete this channel?', { channelGroupId: selectedGroup.value?.id, channelId: selectedChannel.value?.id });
  } else {
    console.error('No group or channel selected');
  }
}

watch(() => guildStore.channelsList?.groups, (newGroups, oldGroups) => {
  if (newGroups !== oldGroups && newGroups != null) {
    const activeIndex = newGroups.map((group, index) => index);
    groupsIndexes.value = activeIndex;
  }
}, { immediate: true });
</script>

<style scoped>
#guild-navigation{
  width: 300px;
  border-right: 1px solid;
  overflow: scroll;

  .guild-header{
    margin-bottom: 2rem;

    h2{
      margin: 0px;
    }    
  }
  
  .router-link-active{
    background-color: lightgray;
  }
}
</style>
<template>
<div class="create-form" v-if="!apiStatusStore.requests.get('put_channel')">
  <form @submit.prevent="onSubmit" :initial-values="formValues">
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="name">Name</label>
      <InputText v-model="name" id="name"/>
      <small id="fullName-help" class="p-error">
        {{ errors.name }}
      </small>
      </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="description">Description</label>
      <InputText v-model="description" id="description"/>
      <small id="fullName-help" class="p-error">
        {{ errors.description }}
      </small>
      </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="position">Position</label>
      <InputText v-model="position" id="position" type="number"/>
      <small id="fullName-help" class="p-error">
        {{ errors.position }}
      </small>
    </div>
    <div class="cta-section flex flex-row gap-2 justify-content-end">
      <Button
        label="Cancel"
        @click="dialogStore.closeDialog()"
      />
      <Button
        type="submit"
        label="Edit"
        :disabled="apiStatusStore.requestPending('put_channel')"
      />
    </div>
  </form>
</div>
<div class="create-pending" v-if="apiStatusStore.requestPending('put_channel')">
  <p>Editing channel...</p>
</div>
<div class="create-complete" v-if="apiStatusStore.requestFulfilled('put_channel')">
  <div class="cta-section flex flex-row gap-3 justify-content-end">
    <Button
      label="Close"
      @click="dialogStore.closeDialog()"
    />
  </div>
</div>
</template>

<script setup lang="ts">
import { defineProps, watch, ref, onMounted } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useDialogStore } from '@/stores/DialogStore';
import { GenericObject, configure, useForm } from 'vee-validate';
import * as yup from 'yup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { Channel, EditChannelPayload } from '@/models/channel-list.model';

const guildStore = useGuildStore();
const apiStatusStore = useApiStatusStore();
const dialogStore = useDialogStore();

const props = defineProps<{
  props: {
    groupId: string,
    channel: Channel
  }
}>();

const formValues = ref({
  name: props.props.channel.name,
  description: props.props.channel.description,
  type: props.props.channel.type,
  position: props.props.channel.position
});

const schema = yup.object({
  name: yup.string().min(4).required(),
  description: yup.string(),
  position: yup.number(),
});

const { defineField, handleSubmit, setValues, errors } = useForm({
  validationSchema: schema,
});

const [name] = defineField('name');
const [description] = defineField('description');
const [position] = defineField('position');

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

onMounted(() => {
  setValues(formValues.value);
});

const onSubmit = handleSubmit (async (values: GenericObject) => {
  if(guildStore.guild){
    const body: EditChannelPayload = {
      guildId: guildStore.guild.id,
      groupId: props.props.groupId ,
      channelId: props.props.channel.id,
      name: values.name,
      description: values.description,
      position: values.position,
    }
    await guildStore.updateChannel(body);
  } else {
    console.error('Cannot edit channel, guild is undefined');
  }
});

watch(() => apiStatusStore.requestFulfilled('put_channel'), (newValue) => {
  if (newValue) {
    useDialogStore().changeHeader('Channel succesfully edited!')
  }
});
</script>
import { Guild, GuildDto } from "@/models/guild.model";
import { defineStore } from "pinia";
import axios, { AxiosError } from 'axios';
import apiConfig from '../config/api';
import { ref } from "vue";

/**
 * Stores the guild that can be visited by the user
 */
export const useGuildNavigationStore = defineStore(`guildNavigation`, () => {
  //state
  const guilds = ref([] as Guild[]);
  const loaded = ref(false);
  const error = ref(null as AxiosError | null);

  //getters

  //actions
  async function fetchGuilds() {
    axios.get(apiConfig.baseURL + '/guild/all')
      .then(res => {
        guilds.value = res.data.map((guild: GuildDto) => new Guild(guild));
        loaded.value = true;
      })
      .catch((error) => {
        error.value = error;
        loaded.value = true;
      });
  }

  return {
    guilds,
    loaded,
    error,
    fetchGuilds
  }
});
export interface ChatMessageDto {
  _id: string;
  userId: string;
  message: string;
  updatedAt: Date;
  channelId: string;
}

export class ChatMessage {
  id: string
  userId: string
  message: string
  updatedAt: Date
  channelId: string

  constructor(
    data: ChatMessageDto
  ){
    this.id = data._id;
    this.userId = data.userId;
    this.message = data.message;
    this.updatedAt = data.updatedAt;
    this.channelId = data.channelId;
  }
}

export class CreateChatMessagePayload {
  constructor(
    public message: string,
    public guildId: string,
    public groupId: string,
    public channelId: string
  ){}
}
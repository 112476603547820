import { defineStore } from "pinia";
import axios from "axios";
import apiConfig from "@/config/api";
import AVLTree from "avl";
import { InitMembersState, Member, OnlineMember, OnlineMemberEvent, OnlineMemberStatus } from "@/models/members.model";
import { computed, ref } from "vue";

type UserId = string;
type Username = string;

export const useMembersStore = defineStore(`members`, () => {
  //store
  const allMembers = ref(new Map<string, Member>());
  const onlineMembersSortedByUsername = ref(new AVLTree<Username, OnlineMember>((a, b) => a.localeCompare(b)));
  const offlineMembersSortedByUsername = ref(new AVLTree<Username, OnlineMember>((a, b) => a.localeCompare(b)));
  const loaded = ref(false);
  const error = ref(null);

  //getters
  const memberUsername = computed(() => (userId: UserId) => {
    const member = allMembers.value.get(userId);
    return member ? member.username : userId;
  });
  const onlineMembers = computed(() => {
    return onlineMembersSortedByUsername.value.values() as OnlineMember[];
  });
  const offlineMembers = computed(() => {
    return offlineMembersSortedByUsername.value.values() as OnlineMember[];
  });

  //actions
  async function fetchMembers(guildId: string){
    const params = { guildId };
    await axios.get(apiConfig.baseURL + `/guild/members`, { params })
      .then((response) => {
        const membersMap = new Map<string, Member>();
        response.data.forEach((member: Member) => {
          membersMap.set(member.userId, new Member(member));
        });
        allMembers.value = membersMap;
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  function setInitMembersState(initStatus: InitMembersState){
    const onlineMembers = new AVLTree<Username, OnlineMember>((a, b) => a.localeCompare(b));
    const offlineMembers = new AVLTree<Username, OnlineMember>((a, b) => a.localeCompare(b));

    initStatus.onlineMembers.forEach((member: OnlineMember) => {
      onlineMembers.insert(member.username, new OnlineMember(member, OnlineMemberStatus.online));
    });
    initStatus.offlineMembers.forEach((member: OnlineMember) => {
      offlineMembers.insert(member.username, new OnlineMember(member, OnlineMemberStatus.offline));
    });
    onlineMembersSortedByUsername.value = onlineMembers;
    offlineMembersSortedByUsername.value = offlineMembers;
  }

  function memberStatusUpdate(event: OnlineMemberEvent){
    const onlineMembers = onlineMembersSortedByUsername.value;
    const offlineMembers = offlineMembersSortedByUsername.value;

    switch(event.onlineMember.status){
      case OnlineMemberStatus.online:
        offlineMembers.remove(event.onlineMember.username);
        onlineMembers.insert(event.onlineMember.username, event.onlineMember);
        onlineMembersSortedByUsername.value = onlineMembers;
        offlineMembersSortedByUsername.value = offlineMembers;
        break;
      case OnlineMemberStatus.offline:
        onlineMembers.remove(event.onlineMember.username);
        offlineMembers.insert(event.onlineMember.username, event.onlineMember);
        onlineMembersSortedByUsername.value = onlineMembers;
        offlineMembersSortedByUsername.value = offlineMembers;
        break;
    }
  }

  return {
    allMembers,
    onlineMembersSortedByUsername,
    offlineMembersSortedByUsername,
    loaded,
    error,
    memberUsername,
    onlineMembers,
    offlineMembers,
    fetchMembers,
    setInitMembersState,
    memberStatusUpdate
  };
});
export enum ChannelAction {
  join = 1,
  leave = 2, //Leave a specific channel
  clear = 3 //Leave all channels
}

export class ChannelEvent {
  action: ChannelAction;
  channelId?: string;
  constructor(action: ChannelAction, channelId?: string){
      this.action = action;
      this.channelId = channelId;
  }
}

export enum GuildAction {
  join = 1,
  leave = 2, //Leave a specific guild
  clear = 3 //Leave active guild
}

export class GuildEvent {
  action: number;
  guildId?: string;
  constructor(action: number, guildId?: string){
      this.action = action;
      this.guildId = guildId;
  }
}

export class NavigationEvent {
  guildId?: string;
  channelId?: string;
  constructor(guildId?: string, channelId?: string){
      this.guildId = guildId;
      this.channelId = channelId;
  }
}
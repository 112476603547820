<template>
  <div class="delete" v-if="!apiStatusStore.requests.get('delete_guild')">
    <p>This action is irreversible and all the server data will be lost</p>
    <div class="cta-section flex flex-row gap-3 justify-content-end">
      <Button 
        label="Cancel"
        @click="$emit('close')"
      />
      <Button
        label="Delete"
        @click="guildStore.deleteGuild()"
        :disabled="apiStatusStore.requestPending('delete_guild')"
      />
    </div>
  </div>
  <div class="delete-pending" v-if="apiStatusStore.requestPending('delete_guild')">
    <p>Deleting...</p>
  </div>
  <div class="delete-complete" v-if="apiStatusStore.requestFulfilled('delete_guild')">
    <p>Guild Deleted</p>
    <div class="flex justify-content-end">
      <Button 
        label="Close"
        @click="closeOnDeleted"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, watch } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useDialogStore } from '@/stores/DialogStore';
import router from '@/router';
import Button from 'primevue/button';

const guildStore = useGuildStore();
const apiStatusStore = useApiStatusStore();
const emit = defineEmits(['close']);

function closeOnDeleted(){
  emit('close');
  router.replace({name: 'base'});
}

watch(() => apiStatusStore.requestFulfilled('delete_guild'), (newValue) => {
  if (newValue) {
    useDialogStore().changeHeader('Guild succesfully deleted')
  }
});
</script>
<template>
<div class="delete" v-if="!apiStatusStore.requests.get('delete_channel')">
  <p>This action is irreversible.</p>
  <div class="cta-section flex flex-row gap-3 justify-content-end">
    <Button
      label="Cancel"
      @click="$emit('close')"
    />
    <Button
      label="Delete"
      @click="onDelete"
      :disabled="apiStatusStore.requestPending('delete_channel')"
    />
  </div>
</div>
<div class="delete-pending" v-if="apiStatusStore.requestPending('delete_channel')">
  <p>Deleting...</p>
</div>
<div class="delete-complete" v-if="apiStatusStore.requestFulfilled('delete_channel')">
  <div class="cta-section flex flex-row gap-3 justify-content-end">
    <Button
      label="Close"
      @click="closeOnDeleted"
    />
  </div>
</div>
</template>
  
<script setup lang="ts">
import { defineProps, defineEmits, watch } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useDialogStore } from '@/stores/DialogStore';
import { DeleteChannelPayload } from '@/models/channel-list.model';
import Button from 'primevue/button';
  
const guildStore = useGuildStore();
const apiStatusStore = useApiStatusStore();
const emit = defineEmits(['close']);

const props = defineProps<{
  props: {
    channelGroupId: string,
    channelId: string
  }
}>()

function onDelete(){
  if(guildStore.guild){
    const payload = new DeleteChannelPayload({
      guildId: guildStore.guild.id,
      groupId: props.props.channelGroupId,
      channelId: props.props.channelId
    });
    guildStore.deleteChannel(payload);
  } else {
    console.error('Cannot delete channel, guild not found');
  }
}

function closeOnDeleted(){
  emit('close');
}

watch(() => apiStatusStore.requestFulfilled('delete_channel'), (newValue) => {
  if (newValue) {
    useDialogStore().changeHeader('Channel succesfully deleted')
  }
});
</script>
// Server channelsList ------------------------------------------------------------

export interface ChannelsListDto {
  _id: string;
  groups: ChannelGroupDto[];
}

export class ChannelsList {
  id: string;
  groups: ChannelGroup[];
  constructor(data: ChannelsListDto){
    this.id = data._id;
    data.groups ? this.groups = data.groups.map((group: ChannelGroupDto) => new ChannelGroup(group)) : this.groups = [];
  }
}

export interface ChannelGroupDto {
  _id: string;
  name: string;
  position: number;
  channels: ChannelDto[];
}

export class ChannelGroup {
  id: string;
  name: string;
  position: number;
  channels: Channel[];
  constructor(data: ChannelGroupDto) {
      this.id = data._id;
      this.name = data.name;
      this.position = data.position;
      this.channels = data.channels.map((channel: ChannelDto) => new Channel(channel))
  }
}

export interface ChannelDto {
  _id: string;
  name: string;
  description: string;
  type: number;
  position: number;
}

export class Channel {
  id: string;
  name: string;
  description: string;
  type: number;
  position: number;
  constructor(data:ChannelDto){
      this.id = data._id;
      this.name = data.name;
      this.description = data.description;
      this.type = data.type;
      this.position = data.position;
  }

  getChannelType(): string {
    switch(this.type) {
      case ChannelType.Chat:
        return 'Chat';
      case ChannelType.Announcements:
        return 'Announcements';
      case ChannelType.Voice:
        return 'Voice';
      case ChannelType.AdvancedVoice:
        return 'AdvancedVoice';
      default:
        return 'Unknown';
    }
  }

  getChannelIcon(): string {
    switch(this.type) {
      case ChannelType.Chat:
        return 'pi pi-comments';
      case ChannelType.Announcements:
        return 'pi pi-bell';
      case ChannelType.Voice:
        return 'pi pi-microphone';
      case ChannelType.AdvancedVoice:
        return 'pi pi-microphone';
      default:
        return 'pi pi-question';
    }
  }
}

export enum ChannelType {
  Chat = 1,
  Announcements,
  Voice,
  AdvancedVoice,
}

// Payloads -----------------------------------------------------------------------

export class CreateChannelGroupPayload {
  guildId: string;
  name: string;
  position?: number;
  constructor(data: CreateChannelGroupPayload){
      this.guildId = data.guildId;
      this.name = data.name;
      this.position = data.position ? data.position : undefined;
  }
}

export class EditChannelGroupPayload {
  guildId: string;
  channelGroupId: string;
  name: string;
  position?: number;
  constructor(data: EditChannelGroupPayload){
      this.guildId = data.guildId;
      this.channelGroupId = data.channelGroupId;
      this.name = data.name;
      this.position = data.position ? data.position : undefined;
  }
}

export class DeleteChannelGroupPayload {
  guildId: string;
  channelGroupId: string;
  constructor(data: DeleteChannelGroupPayload){
      this.guildId = data.guildId;
      this.channelGroupId = data.channelGroupId;
  }
}

export class CreateChannelPayload {
  guildId: string;
  name: string;
  description?: string;
  type: ChannelType;
  position?: number;
  channelGroupId?: string;
  constructor(data: CreateChannelPayload){
      this.guildId = data.guildId;
      this.name = data.name;
      this.description = data.description ? data.description : undefined;
      this.type = data.type;
      this.position = data.position ? data.position : undefined;
      this.channelGroupId = data.channelGroupId ? data.channelGroupId : undefined;
  }
}

export class EditChannelPayload {
  guildId: string;
  groupId: string;
  channelId: string;
  name?: string;
  description?: string;
  position?: number;
  constructor(data: EditChannelPayload){
      this.guildId = data.guildId;
      this.groupId = data.groupId;
      this.channelId = data.channelId;
      this.name = data.name;
      this.description = data.description ? data.description : undefined;
      this.position = data.position ? data.position : undefined;
  }
}

export class DeleteChannelPayload {
  guildId: string;
  groupId: string;
  channelId: string;
  constructor(data: DeleteChannelPayload){
      this.guildId = data.guildId;
      this.groupId = data.groupId;
      this.channelId = data.channelId;
  }
}
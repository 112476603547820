<template>
<section id="login" class="flex flex-column text-center">
  <h1 class="mt-5">Login</h1>
  <user-login class="mx-auto"/>
  <p>If you don't already have an account:</p>
  <Button
    label="Go to registration"
    class="mx-auto"
    @click="onGoToRegister"
  />
</section>
</template>

<script setup lang="ts">
import UserLogin from '@/components/UserLogin.vue';
import { useRouter } from 'vue-router';
import Button from 'primevue/button';

const router = useRouter();

function onGoToRegister(){
  router.push('/register');
}
</script>
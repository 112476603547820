<template>
<section id="chat" class="flex-grow-1">
  <div class="title-row p-3">
    <h2>{{ guildStore.activeChannel?.name }}</h2>
  </div>
  <div
    class="messages p-3"
    v-if="chatStore.loaded"
    @scroll.passive="handleScroll"
    ref="scrollContainerRef"
  >
    <guild-chat-message
      class="mb-2"
      v-for="message in chatStore.messages"
      :key="message.id"
      :message="message"
    />
  </div>
  <div v-else class="messages p-3">
    Loading...
  </div>
  <div
    class="unread-notify"
    v-if="firstAutoScrollDone && !scrolledToBottom && chatStore.lastMessage?.id !== lastViewedMessageId"
    @click="scrollToBottom"  
  >
    You have new unread messages
  </div>
  <Form ref="form" :validation-schema="schema" @submit="onSubmit" v-slot="{ isSubmitting, meta}">
    <div class="chat-input">
      <Field type="text" name="message" :value="formValues.message"/>
      <button type="submit" :disabled="isSubmitting || !meta.valid" class="hidden">
        Send
      </button>
    </div>
  </Form>
</section>
</template>

<script setup lang="ts">
import GuildChatMessage from './GuildChatMessage.vue';
import { ref, watch } from 'vue';

import { Form, Field } from 'vee-validate';
import * as yup from 'yup';

import { useGuildStore } from '@/stores/GuildStore';
import { useChatStore } from '@/stores/ChatStore';

const guildStore = useGuildStore();
const chatStore = useChatStore();

const scrollContainerRef = ref<HTMLDivElement | null>(null);
const formValues = ref({
  message: ''
});
const firstAutoScrollDone = ref(false);
const scrolledToBottom = ref(false);
const lastViewedMessageId = ref<string | null>(null);
const firstViewedMessageId = ref<string | null>(null);

const schema = yup.object({
  message: yup.string().min(1).required(),
});

function onSubmit(values: any, { resetForm }: any) {
  chatStore.sendMessage(values.message as string);
  resetForm();
}

function scrollToBottom(){
  if(scrollContainerRef.value){
    scrollContainerRef.value.scrollTop = scrollContainerRef.value.scrollHeight;
  }
  if(firstAutoScrollDone.value === false){
    firstAutoScrollDone.value = true;
  }
}

const handleScroll = (e: Event) => {
  const target = e.target as HTMLDivElement;

  //scroll to bottom handler
  if (target.scrollHeight - target.scrollTop === target.clientHeight) {
    scrolledToBottom.value = true;
    lastViewedMessageId.value = chatStore.lastMessage?.id;
  } else {
    scrolledToBottom.value = false;
  }

  //fetch more messages handler
  if (target.scrollTop === 0) {
    firstViewedMessageId.value = chatStore.firstMessage?.id;
    chatStore.fetchOlderMessages();
  }
};

//Scrolls to bottom of chat when new messages are fetched
watch(
  () => ({
    firstMessage: chatStore.firstMessage,
    lastMessage: chatStore.lastMessage,
    messagesLoaded: chatStore.loaded,
    scrollContainer: scrollContainerRef.value
  }),
  ({ firstMessage, lastMessage, scrollContainer }) => {
    //I am at the bottom and recieve a new message
    if (lastMessage && scrollContainer && (scrolledToBottom.value === true  || firstAutoScrollDone.value === false)) {
      setTimeout(() => {
        lastViewedMessageId.value = lastMessage.id;
        console.log('scrolling to bottom');
        scrollToBottom();
      }, 0);
    }
    if(firstMessage && scrollContainer && firstViewedMessageId.value !== firstMessage.id){
      setTimeout(() => {
        const firstViewedMessage = document.getElementById(`${firstViewedMessageId.value}`);
        if(firstViewedMessage){
          firstViewedMessage.scrollIntoView();
        }
      }, 0);
    }
  }
);

//watch activeGuildId and if changes reset refs
watch(() => guildStore.activeChannel?.id, (newGuildId, oldGuildId) => {
  if (newGuildId && newGuildId !== oldGuildId) {
    console.log('resetting refs');
    firstViewedMessageId.value = null;
    lastViewedMessageId.value = null;
    firstAutoScrollDone.value = false;
  }
});
</script>

<style scoped>
#chat{
  height: 100%;
  display: flex;
  flex-direction: column;

  .title-row{
    background-color: #10b981;
    width: 100%;

    h2{
      margin: 0;
    }
  }

  .messages{
    height: 100%;
    overflow-y: scroll;    
  }

  .unread-notify{
    background-color: red;
    color: white;
    padding: 0.25rem;
    text-align: center;
  }

  .chat-input{
    display: flex;
    width: calc(100%);
    padding: 0rem 1rem 1rem 1rem;

    input{
      width: 100%;
    }

    button{
      margin-left: 1rem;
    }
  }
}
</style>
import axios from 'axios';
import apiConfig from '../config/api';

import { defineStore } from "pinia";
import { Guild, GuildRegisterPayload, GuildUpdatePayload } from "@/models/guild.model";
import { ChannelsList, CreateChannelGroupPayload, EditChannelGroupPayload, CreateChannelPayload, EditChannelPayload, DeleteChannelPayload, ChannelGroup, Channel } from "@/models/channel-list.model";
import { ChannelAction, ChannelEvent } from "@/models/socket.model";

import { useGuildNavigationStore } from "./GuildNavigationStore";
import { useUserStore } from './UserStore';
import { useChatStore } from './ChatStore';
import { useSocketStore } from './SocketStore';
import { useMembersStore } from './MembersStore';
import { computed, ref } from 'vue';

export const useGuildStore = defineStore( `guild`, () => {
  const userStore = useUserStore();

  //state ----------------------------------------------------------------------------------

  const guild = ref(undefined as Guild | undefined);
  const isMember = ref(false);
  const channelsList = ref(undefined as ChannelsList | undefined);
  const activeChannelGroup = ref(undefined as ChannelGroup | undefined);
  const activeChannel = ref(undefined as Channel | undefined);
  const loaded = ref(false);
  const error = ref(undefined as string | undefined);

  //getters --------------------------------------------------------------------------------

  const isOwner = computed(() => {
    const user = userStore.user;
    return guild.value?.owners.some(owner => owner === user?._id);
  });

  //actions --------------------------------------------------------------------------------

  /** Guild CRUD */

  async function createGuild(guild: GuildRegisterPayload){
    await axios.post(apiConfig.baseURL + '/guild', guild)
      .then(() => {
        loaded.value = true;
        useGuildNavigationStore().fetchGuilds();
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  async function getGuildById(guildId: string){
    const params = { guildId };
    await axios.get(apiConfig.baseURL + `/guild`, { params })
      .then(res => {
        guild.value = new Guild(res.data.guild);
        channelsList.value = new ChannelsList(res.data.channelsList);
        isMember.value = res.data.member ? true : false;
        loaded.value = true;
        const socketStore = useSocketStore();
        socketStore.sendNavigationEvent({ guildId: guild.value.id});
        const membersStore = useMembersStore();
        membersStore.fetchMembers(guild.value.id);
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  async function updateGuild(payload: GuildUpdatePayload){
    await axios.put(apiConfig.baseURL + `/guild`, payload)
      .then(res => {
        guild.value = new Guild(res.data);
        loaded.value = true;
        getGuildById(guild.value.id);
        //TO DO - instead of fetching the guild again, update the guild in the store using the Id
        const guildNavigationStore = useGuildNavigationStore();
        guildNavigationStore.fetchGuilds();
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  async function deleteGuild(guildId?: string){
    if(!guildId) guildId = guild.value?.id;
    const params = { guildId };
    await axios.delete(apiConfig.baseURL + `/guild`, { params })
      .then(() => {
        loaded.value = true;
        useGuildNavigationStore().fetchGuilds();
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  /** Join - Leave */

  async function joinGuild(guildId?: string){
    if(!guildId) guildId = guild.value?.id;
    const body = { guildId };
    await axios.post(apiConfig.baseURL + `/guild/join`, body)
      .then(() => {
        isMember.value = true;
        useGuildNavigationStore().fetchGuilds();
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  async function leaveGuild(guildId?: string){
    const id = guildId ? guildId : guild.value?.id;
    const params = { guildId: id };
    await axios.post(apiConfig.baseURL + `/guild/leave`, params)
      .then(() => {
        useGuildNavigationStore().fetchGuilds();
        isMember.value = false;
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  /** Utility */

  function clearGuild(){
    guild.value = undefined;
    isMember.value = false;
    channelsList.value = undefined;
    activeChannelGroup.value = undefined;
    activeChannel.value = undefined;
    loaded.value = false;
    error.value = undefined;
  }

  /** ChannelsGroups CRUD */

  async function createChannelGroup(payload: CreateChannelGroupPayload){
    if(guild.value){
      payload.guildId = guild.value.id;
      await axios.post(apiConfig.baseURL + `/channel-group`, payload)
        .then(res => {
          channelsList.value = new ChannelsList(res.data);
          loaded.value = true;
        })
        .catch(() => {
          loaded.value = true;
        });  
    } else {
      console.error('Guild not found');
    }
  }

  async function updateChannelGroup(payload: EditChannelGroupPayload){
    if(guild.value){
      payload.guildId = guild.value.id;
      await axios.put(apiConfig.baseURL + `/channel-group`, payload)
        .then(res => {
          channelsList.value = new ChannelsList(res.data);
          loaded.value = true;
        })
        .catch(() => {
          loaded.value = true;
        });  
    } else {
      console.error('Guild not found');
    }
  }
  
  async function deleteChannelGroup(channelGroupId: string){
    const payload = { guildId: guild.value?.id, channelGroupId: channelGroupId };
    axios.delete(apiConfig.baseURL + `/channel-group`, { data: payload })
      .then(res => {
        channelsList.value = new ChannelsList(res.data);
        loaded.value = true;
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  /** Channels CRUD */

  async function createChannel(payload: CreateChannelPayload){
    if(guild.value){
      payload.guildId = guild.value.id;
      await axios.post(apiConfig.baseURL + `/channel`, payload)
        .then(res => {
          channelsList.value = new ChannelsList(res.data);
          loaded.value = true;
        })
        .catch(() => {
          loaded.value = true;
        });  
    } else {
      console.error('Guild not found');
    }
  }

  async function updateChannel(payload: EditChannelPayload){
    await axios.put(apiConfig.baseURL + `/channel`, payload)
      .then(res => {
        channelsList.value = new ChannelsList(res.data);
        loaded.value = true;
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  async function deleteChannel(payload: DeleteChannelPayload){
    axios.delete(apiConfig.baseURL + `/channel`, { data: payload })
      .then(res => {
        channelsList.value = new ChannelsList(res.data);
        loaded.value = true;
      })
      .catch(() => {
        loaded.value = true;
      });
  }

  /** Channels navigation */

  function setActiveChannel(channelId: string){
    const socketStore = useSocketStore();
    if(guild.value){
      socketStore.sendNavigationEvent({ guildId: guild.value?.id, channelId });
    }
  }

  /**
   * Handles the channel event recieved from the socket
   */
  function handleChannelEvent(channelEvent: ChannelEvent){
    switch(channelEvent.action){
      case ChannelAction.join:
        activeChannelGroup.value = channelsList.value?.groups.find(group => group.channels.find(channel => channel.id === channelEvent.channelId))
        activeChannel.value = channelsList.value?.groups.find(group => group.channels.find(channel => channel.id === channelEvent.channelId))?.channels.find(channel => channel.id === channelEvent.channelId);
        if(activeChannel.value){
          const chatStore = useChatStore();
          chatStore.onChannelJoined(activeChannel.value.id);
        }
        break;
      case ChannelAction.leave:
        activeChannelGroup.value = undefined;
        activeChannel.value = undefined;
        break;
      case ChannelAction.clear:
        activeChannelGroup.value = undefined;
        activeChannel.value = undefined;
        break;
      default:
        break;
    }
  }

  function clearedChannel(){
    activeChannelGroup.value = undefined;
    activeChannel.value = undefined;
  }
  
  return {
    guild,
    isMember,
    channelsList,
    activeChannelGroup,
    activeChannel,
    loaded,
    error,
    isOwner,
    createGuild,
    getGuildById,
    updateGuild,
    deleteGuild,
    joinGuild,
    leaveGuild,
    clearGuild,
    createChannelGroup,
    updateChannelGroup,
    deleteChannelGroup,
    createChannel,
    updateChannel,
    deleteChannel,
    setActiveChannel,
    handleChannelEvent,
    clearedChannel
  };

});
<template>
  <div id="base" v-if="loadingStore.appLoaded">
    <the-base-navigation />
    <router-view></router-view>
  </div>
  <div class="app-loading" v-else>
    <p>Establisting connection to the server...</p>
  </div>
</template>

<script setup lang="ts">
import TheBaseNavigation from '../components/TheBaseNavigation.vue';
import { useLoadingStore } from '@/stores/LoadingStore';

const loadingStore = useLoadingStore();
</script>

<style scoped>
#base{
  --guild-member-list-width: 450px;

  height: 100%;
  width: 100%;
  display: flex;

}
</style>
<template>
<div class="create-form" v-if="!apiStatusStore.requests.get('post_guild')">
  <form @submit.prevent="onSubmit" :validation-schema="schema">
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="name">Guild name</label>
      <InputText v-model="name" id="name" type="name" :class="{ 'p-invalid': errors.name }"/>
      <small id="fullName-help" class="p-error">
        {{ errors.name }}
      </small>
    </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="description">Description</label>
      <InputText v-model="description" id="description" type="description" :class="{ 'p-invalid': errors.description }"/>
      <small id="fullName-help" class="p-error">
        {{ errors.description }}
      </small>
    </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="isPrivate">Is this a private server?</label>
      <Checkbox v-model="isPrivate" id="isPrivate" :value="false" binary :class="{ 'p-invalid': errors.isPrivate }"/>
    </div>
    <div class="cta-section flex flex-row gap-3 justify-content-end">
      <Button 
        label="Cancel"
        @click="$emit('close')"
      />
      <Button
        type="submit"
        label="Create"
        :disabled="apiStatusStore.requestPending('post_guild')"
      />
    </div>
  </form>
</div>
<div class="post_guild-pending" v-if="apiStatusStore.requestPending('post_guild')">
  <p>Creating guild...</p>
</div>
<div class="post_guildd" v-if="apiStatusStore.requestFulfilled('post_guild')">
  <div class="flex justify-content-end">
    <Button 
      label="Close"
      @click="$emit('close')"
    />
  </div>
</div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useDialogStore } from '@/stores/DialogStore';
import { useForm, configure } from 'vee-validate';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import * as yup from 'yup';

const guildStore = useGuildStore();
const apiStatusStore = useApiStatusStore();

const schema = yup.object({
  name: yup.string().min(4).required(),
  description: yup.string(),
  isPrivate: yup.boolean(),
});

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: schema,
});

const [name] = defineField('name');
const [description] = defineField('description');
const [isPrivate] = defineField('isPrivate');

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

const onSubmit = handleSubmit((values: any) => {
  guildStore.createGuild(values);
});

watch(() => apiStatusStore.requestFulfilled('post_guild'), (newValue) => {
  if (newValue) {
    useDialogStore().changeHeader('Guild succesfully created')
  }
});
</script>
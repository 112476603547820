<template>
<div class="create-form" v-if="!apiStatusStore.requests.get('put_guild')">
  <form @submit.prevent="onSubmit" :initial-values="formValues">
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="name">Name</label>
      <InputText v-model="name" id="name"/>
      <small id="fullName-help" class="p-error">
        {{ errors.name }}
      </small>
      </div>
    <div class="field flex flex-column gap-2 align-items-start">
      <label for="description">Description</label>
      <InputText v-model="description" id="description"/>
      <small id="fullName-help" class="p-error">
        {{ errors.description }}
      </small>
    </div>
    <div class="cta-section flex flex-row gap-2 justify-content-end">
      <Button
        label="Cancel"
        @click="emits('close')"
      />
      <Button
        type="submit"
        label="Edit"
        :disabled="apiStatusStore.requestPending('put_guild')"
      />
    </div>
  </form>
</div>
<div class="create-pending" v-if="apiStatusStore.requestPending('put_guild')">
  <p>Editing guild...</p>
</div>
<div class="create-complete" v-if="apiStatusStore.requestFulfilled('put_guild')">
  <div class="cta-section flex flex-row gap-3 justify-content-end">
    <Button
      label="Close"
      @click="emits('close')"
    />
  </div>
</div>
</template>

<script setup lang="ts">
import { defineEmits, watch, ref, onMounted } from 'vue';
import { useGuildStore } from '@/stores/GuildStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useDialogStore } from '@/stores/DialogStore';
import { GenericObject, configure, useForm } from 'vee-validate';
import * as yup from 'yup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { GuildUpdatePayload } from '@/models/guild.model';

const guildStore = useGuildStore();
const apiStatusStore = useApiStatusStore();
const emits = defineEmits(['close']);

const formValues = ref({
  name: guildStore.guild ? guildStore.guild.name : '',
  description: guildStore.guild ? guildStore.guild.description : '',
});

const schema = yup.object({
  name: yup.string().min(4).required(),
  description: yup.string(),
});

const { defineField, handleSubmit, setValues, errors } = useForm({
  validationSchema: schema,
});

const [name] = defineField('name');
const [description] = defineField('description');

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

onMounted(() => {
  if(guildStore.guild){
    setValues({
      name: guildStore.guild.name,
      description: guildStore.guild.description,
    });
  }
});

const onSubmit = handleSubmit( async (values: GenericObject) => {
  if(guildStore.guild){
    const body: GuildUpdatePayload = {
      id: guildStore.guild.id,
      name: values.name,
      description: values.description,
    }
    await guildStore.updateGuild(body);
  } else {
    console.error('Cannot edit guild, guild is undefined');
  }
});

watch(() => apiStatusStore.requestFulfilled('put_guild'), (newValue) => {
  if (newValue) {
    useDialogStore().changeHeader('Guild succesfully edited!')
  }
});
</script>

<style scoped></style>
<template>
<section id="members-lists" class="p-3">
  <h2 class="mt-0">Members list</h2>
  <h3>Online Members</h3>
  <div class="members-list">
    <GuildMember v-for="member in membersStore.onlineMembers" :key="member.id" :member="member" />
  </div>
  <h3>Offline Members</h3>
  <div class="members-list">
    <GuildMember v-for="member in membersStore.offlineMembers" :key="member.id" :member="member" />
  </div>
</section>
</template>

<script setup lang="ts">
import { useMembersStore } from '@/stores/MembersStore';
import GuildMember from './GuildMember.vue';

const membersStore = useMembersStore();
</script>

<style scoped>
#members-lists{
  display: flex;
  flex-direction: column;
  --guild-member-list-width: 300px;
  width: var(--guild-member-list-width);
  height: 100%;
  border-left: 1px solid black;
  margin-left:auto;

  .members-list{

    h2{
      margin: 0
    }

    h3{
      margin: 0;
    }
    
  }
}
</style>
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Router } from "vue-router";
import PrimeVue from "primevue/config";
import { createPinia } from "pinia";
import { markRaw } from "vue";
import "./interceptors/jwt.interceptor"
import "./interceptors/api-store.interceptor"
import './assets/styles.scss'; 
import 'primeflex/primeflex.css' 
import 'primevue/resources/themes/aura-light-green/theme.css'; 
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

declare module 'pinia' {
export interface PiniaCustomProperties {
      router: Router;
    }
}

const pinia = createPinia();
      pinia.use(({ store }) => { store.router = markRaw(router) });

const app = createApp(App);

app.use(pinia).use(router).use(PrimeVue).mount("#app");

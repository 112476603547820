<template>
<section id="page-not-found" class="flex flex-column text-center mt-5">
  <h1>404 - Page Not Found</h1>
  <p>You have backstrafed too far from any known url</p>
  <div class="cta-section flex mx-auto gap-4">
    <Button
      label="Go to homepage"
      link
      @click="navigateTo('/')"
    />
    <Button
      v-if="userStore.loaded && userStore.user"
      label="Go to Base"
      link
      @click="navigateTo('/base')"
    />
  </div>
</section>
</template>

<script setup lang="ts">
import { useUserStore } from '../stores/UserStore';
import Button from 'primevue/button';
import router from '@/router';

function navigateTo(to: string){
  router.push(to);
}

const userStore = useUserStore();
</script>
import { defineStore } from "pinia";
import { useUserStore } from "./UserStore";
import { useSocketStore } from "./SocketStore";
import { ref } from "vue";

export const useLoadingStore = defineStore(`loading`, () => {
  const socketStore = useSocketStore();
  const userStore = useUserStore();

  //state
  const initLoadingDone = ref(false);

  //getters
  const appLoaded = () => {
    return initLoadingDone.value && socketStore.isConnected ? true : false;
  };

  //onStoreInit
  async function onStoreInit(){
    await userStore.tryLogin()
      .then(() => {
        initLoadingDone.value = true;
      });
  }

  //actions
  function setLoading(loading: boolean){
    initLoadingDone.value = loading;
  }

  onStoreInit();

  return {
    initLoadingDone,
    appLoaded,
    onStoreInit,
    setLoading
  };
});
import { defineStore } from "pinia";
import { RequestInfo, RequestStatus } from "@/models/request.model";
import { computed, ref } from "vue";

/**
 * Store for managing the status of API requests, keeping track of their status and errors.
 * Requests are identified by their name, which is a combination of the HTTP method and the URL.
 * Requests are set automatically by the axios interceptor, and can be reset manually.
 * It get reset on every route change
 */
export const useApiStatusStore = defineStore(`apiStatus`, () => {

  //state
  const requests = ref(new Map<string, RequestInfo>());

  //getters
  const requestExists = computed(() => (requestName: string) => {
    return requests.value.has(requestName);
  });
  const requestPending = computed(() => (requestName: string) => {
    return requests.value.get(requestName)?.status === RequestStatus.Pending;
  });
  const requestFulfilled = computed(() => (requestName: string) => {
    return requests.value.get(requestName)?.status === RequestStatus.Fulfilled;
  });
  const requestRejected = computed(() => (requestName: string) => {
    return requests.value.get(requestName)?.status === RequestStatus.Rejected;
  });
  const getRequestStatus = computed(() => (requestName: string) => {
    return requests.value.get(requestName)?.status;
  });
  const getRequestError = computed(() => (requestName: string) => {
    return requests.value.get(requestName)?.error;
  });

  //actions
  function setRequestStatus(requestName: string, info: RequestInfo){
    requests.value.set(requestName, info);
  }
  function resetRequestStatuses(){
    requests.value.clear();
  }

  //watchers test
  /*const unsubSocketConnection = socketStore.$onAction(
    ({ name, store, args, after, onError}) => {
      if(name === 'connect')
        console.log(`apiStore onAction test: on socket connect log`);
    }
  )*/

  return {
    requests,
    requestExists,
    requestPending,
    requestFulfilled,
    requestRejected,
    getRequestStatus,
    getRequestError,
    setRequestStatus,
    resetRequestStatuses,
  };
})
<template>
<form @submit.prevent="onSubmit">
  <div class="field flex flex-column gap-2 align-items-start">
    <label for="email">Email</label>
    <InputText v-model="email" id="email" type="email" />
    <small id="fullName-help" class="p-error">
      {{ errors.email }}
    </small>
  </div>
  <div class="field flex flex-column gap-2 align-items-start">
    <label for="password">Password</label>
    <InputText v-model="password" id="password" type="password" />
    <small id="fullName-help" class="p-error">
      {{ errors.password }}
    </small>
  </div>
  <Button
    type="submit"
    label="Login"
    :disabled="apiStatusStore.requestPending('login')"
  />
</form>
</template>

<script setup lang="ts">
import { useUserStore } from '../stores/UserStore';
import { useApiStatusStore } from '@/stores/ApiStatusStore';
import { useForm, configure } from 'vee-validate';
import * as yup from 'yup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

const userStore = useUserStore();
const apiStatusStore = useApiStatusStore();

const schema = yup.object().shape({
  email: yup.string().required().email().label('Email Address'),
  password: yup.string().required().min(8)
    .matches(/(?=.*[0-9])/, 'Password must contain at least one number')
    .matches(/(?=.*[@#$%^&+=.,!?])/, 'Password must contain at least one special character').label('Password'),
});

const { defineField, handleSubmit, errors } = useForm({
  validationSchema: schema,
});

const [email] = defineField('email');
const [password] = defineField('password');

configure({
  validateOnBlur: true,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

const onSubmit = handleSubmit((values: any) => {
  userStore.loginUser(values);
});
</script>
import { defineStore } from 'pinia';
import { Component, markRaw, ref } from 'vue';
import { useApiStatusStore } from './ApiStatusStore';

export const useDialogStore = defineStore(`dialog`, () => {
  //state
  const isVisible = ref(false);
  const component = ref(null as Component | null);
  const header = ref('');
  const props = ref({});

  //actions
  function openDialog(componnt: Component, headr: string, prps: Record<string, any> = {}){
    component.value = markRaw(componnt);
    header.value = headr;
    props.value = prps;
    isVisible.value = true;
  }

  function closeDialog(){
    isVisible.value = false;
    component.value = null;
    header.value = '';
    props.value = {};
    useApiStatusStore().resetRequestStatuses();
  }
  function changeHeader(headr: string){
    header.value = headr;
  }

  return {
    isVisible,
    component,
    header,
    props,
    openDialog,
    closeDialog,
    changeHeader
  };
});